import styled from 'styled-components';
import { theme } from "../../theme";

export const CommentSectionContainer = styled.div`
  background-color: ${theme.backgroundColor};
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

export const CommentInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const CommentInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid ${theme.secondaryColor};
  border-radius: 5px;
`;

export const CommentSubmitButton = styled.button`
  background-color: ${theme.primaryColor};
  color: ${theme.buttonTextColor};
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
`;

export const CommentsList = styled.div`
  > div {
    background-color: ${theme.cardBackground};
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    box-shadow: 0 0 5px ${theme.cardShadow};

    p {
      margin: 5px 0;
      color: ${theme.descriptionColor};
    }
  }
`;

export const CommentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  > div {
    background-color: ${theme.cardBackground};
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    box-shadow: 0 0 5px ${theme.cardShadow};

    p {
      margin: 5px 0;
      color: ${theme.descriptionColor};
    }
  }
`;

export const CommentText = styled.p`
  margin-right: 5px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  max-width: 100%; /* Allow the text to wrap if it overflows */

  &.show-full-text {
    white-space: normal;
    overflow: visible;
    text-overflow: unset;
    word-wrap: break-word;
    word-break: break-all;
  }
`;

export const CommentAuthor = styled.p`
  font-weight: bold;
  color: ${theme.textColor};
`;

export const CommentTimestamp = styled.p`
  flex: 1;
  max-width: 100%;
  font-style: italic;
  padding: 0 10px;
  text-align: right; /* Align the content to the right */
  color: ${theme.descriptionColor};
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CommentButtons = styled.div`
  display: flex;
  align-items: center;
`;

export const DeleteButton = styled.button`
  background: ${theme.secondaryButtonBackground};
  color: ${theme.secondaryButtonTextColor};
  border: none;
  padding: 8px 12px;
  cursor
`;

export const LoadMoreButton = styled.button`
  background: ${theme.buttonBackground};
  color: ${theme.buttonTextColor};
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background: ${theme.buttonHoverBackground};
    color: ${theme.buttonTextHoverColor};
  }
`;