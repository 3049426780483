import React, { useEffect } from "react";
import Auth from "../../utils/auth";
import { loadStripe } from "@stripe/stripe-js";
import { useLazyQuery } from "@apollo/react-hooks";
import { useSelector, useDispatch } from "react-redux";
import { TOGGLE_CART, ADD_MULTIPLE_TO_CART } from "../../utils/actions";
import { QUERY_CHECKOUT } from "../../utils/queries";
import { idbPromise } from "../../utils/helpers";
import {
  CartContainer,
  IconWrap,
  CartIcon,
} from "../../components/Checkout/CheckoutElements";
import CartItems from "../../components/CartItems/CartItems";
import {
  CartBody,
  CartHeaderWraper,
  CartHeader,
  CartHeaderItem,
  CloseItem,
  CloseIcon,
  BtnLink,
  BtnCheckout,
} from "./CartItem.Elements";
// if(process.env.NODE_ENV !== 'production')
// {
//   require('dotenv').config();
// }
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function Cartbar() {
  const stateCart = useSelector((state) => state.cart);
  const cartOpen = useSelector((state) => state.cartOpen);
  const dispatch = useDispatch();

  const [getCheckout, { data }] = useLazyQuery(QUERY_CHECKOUT);

  useEffect(() => {
    if (data) {
      stripePromise.then((res) => {
        res.redirectToCheckout({ sessionId: data.checkout.session });
      });
    }
  }, [data]);

  useEffect(() => {
    async function getCart() {
      const cart = await idbPromise("cart", "get");
      dispatch({ type: ADD_MULTIPLE_TO_CART, products: [...cart] });
    }

    if (!stateCart.length) {
      getCart();
    }
  }, [stateCart.length, dispatch]);

  function toggleCart() {
    dispatch({ type: TOGGLE_CART });
  }

  function calculateTotal() {
    let sum = 0;
    stateCart.forEach((item) => {
      sum += item.price * item.purchaseQuantity;
    });
    return sum.toFixed(2);
  }

  function submitCheckout() {
    const productIds = [];

    stateCart.forEach((item) => {
      for (let i = 0; i < item.purchaseQuantity; i++) {
        productIds.push(item._id);
      }
    });

    getCheckout({
      variables: { products: productIds },
    });
  }

  if (!cartOpen) {
    return (
      <CartContainer onClick={toggleCart}>
        <IconWrap role="img" aria-label="cart">
          <CartIcon />
        </IconWrap>
      </CartContainer>
    );
  }

  return (
    <CartBody>
      <CartHeaderWraper>
        <CartHeader>Panier</CartHeader>
        <CartHeaderItem>
          <CloseItem>
            <CloseIcon onClick={toggleCart} />
          </CloseItem>
        </CartHeaderItem>
      </CartHeaderWraper>
      {stateCart.length ? (
        <div>
          {stateCart.map((item) => (
            <CartItems key={item._id} item={item} />
          ))}
          <div>

            <span style={{ marginRight: "3rem" }}>
              <strong>Total: {calculateTotal()}€</strong>
            </span>

            {Auth.loggedIn() ? (
              <BtnCheckout
                
                onClick={submitCheckout}
              >
                Passer au paiement
              </BtnCheckout>
            ) : (
              <>
                <BtnLink to="/login">
                  <span>(connectez-vous pour payer)</span>
                </BtnLink>
              </>
            )}
          </div>
        </div>
      ) : (
        <h3>Vous n'avez encore rien ajouté au panier.</h3>
      )}
    </CartBody>
  );
}
