export const templateObjPrivacy = {
  primary: true,
  lightBg: true,
  lightLastUpdate: true,
  lightText: false,
  lightText2: false,
  lightText3: false,
  lightTextDesc1: false,
  lightTextDesc2: false,
  lightTextDesc3: false,
  lightTextDesc4: false,
  lightTextDesc5: false,
  lightTextDesc6: false,
  lightTextDesc7: false,
  lightTextDesc8: false,
  lightTextDesc9: false,
  lastUpdate: "Last Updated: November 13, 2021",
  headline: "Privacy Policy",
  title: "ADVISEMENT",
    paragraph1:
      "The Code Command web application is created for educational purposes only. The application features may be functional, but the content is completely fabricated and offers little to no instructional value and is intended for demonstration purposes only. The content contained in this document is for demonstration purposes, and in no way intends to represent, convey, or enforce any legal covenants.",
    paragraph2: `Code Command, Inc. and its affiliates and subsidiaries (collectively, "Code Command," "we," "our," or "us") are committed to protecting your privacy. We have prepared this Privacy Policy to describe our practices regarding the information processed by our products and any other Code Command products that link to this Privacy Policy (collectively "Products").`,
    paragraph3:
      "This Privacy Policy is generally applicable to all of Code Command's products unless otherwise specified.",
    paragraph4:
      "To learn about Code Command's information practices for data collected through our corporate sites, please visit https://www.CodeCommand.com/policies/marketing-privacy.",
  title2: "FOR EU AND EEA RESIDENTS",
    paragraph5:
      "Code Command complies with the EU-U.S. Privacy Shield Framework and the Swiss-U.S. Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information transferred from the European Union (“EU”) and Switzerland to the United States (“U.S.”) respectively, as that term is defined in the Privacy Shield Framework. Code Command has certified to the Department of Commerce that it adheres to the Privacy Shield Principles. Code Command is committed to subjecting all personal data received from the EU and Switzerland, in reliance on the Privacy Shield Framework, to the Framework’s applicable principles. If there is any conflict between the terms in this privacy policy and the Privacy Shield Principles, the Privacy Shield Principles shall govern. To learn more about the Privacy Shield Framework, visit the U.S. Department of Commerce’s Privacy Shield List: https://www.privacyshield.gov/list.",
    paragraph6:
      "Code Command’s privacy policy, as provided below, describes the types of personal information that Code Command collects, the types of third parties to which it discloses personal data, and the purposes for which it does so. Code Command collects contact and demographic information to respond to requests or to personalize and deliver the services. Code Command transfers this information to third parties acting as an agent on its behalf. Code Command is accountable for the processing of personal data it receives, under the Privacy Shield Framework, and subsequently transfers to a third party. Code Command complies with the Privacy Shield Principles for all onward transfers of personal data from the EU and Switzerland, including the onward transfer liability provisions.",
    paragraph7:
      "Residents of the EU and Switzerland have the right to access the personal information that Code Command maintains, and in some cases, may have the right to correct or amend information that is inaccurate or has been processed in violation of the Privacy Shield Principles, to the extent allowed by law. To exercise this right, contact us at privacy@code-command.com. To learn more about the choices you have over your personal information, please see the subsection below entitled, “Your Choices Regarding Your Personal Information.”",
    paragraph8:
      "With respect to personal data received or transferred pursuant to the Privacy Shield Framework, Code Command is subject to the regulatory enforcement powers of the U.S. Federal Trade Commission. In certain situations, Code Command may be required to disclose personal data in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.",
    paragraph9:
      "Residents of the EU and Switzerland with inquiries or complaints regarding this Privacy Policy should first contact Code Command at:",
    address1:
    "privacy@code-command.com",
    address2:
    "Code Command, Inc % Dewey, Cheatem, & Howe, LLP; ",
    address3:
    "One Park Avenue, New York, NY 10001",
    address4:
    "212-111-1111",
  title3: "DATA YOU PROVIDE TO US",
    paragraph11:
      "We collect information from you, such as first and last name, gender, e-mail and mailing addresses, professional title, company name, and password when you create an account to log in to our network. When you order Services on our Site or Apps, you provide us or our third party payment processor with information necessary to complete the transaction, which may include your name, credit card information, billing information, and shipping information. We also may retain information on your behalf, such as files and messages that you store using your account. If you provide us feedback or contact us via e-mail, we will collect your name and e-mail address, as well as any other content included in the e-mail. When you participate in one of our surveys, we may collect additional profile information. We also collect other types of personal information and demographic information that you provide to us voluntarily.",
};

export const templateObjCookies = {
  primary: true,
  lightBg: true,
  lightLastUpdate: true,
  lightText: false,
  lightTextDesc1: false,
  lastUpdate: "Last Updated: November 20, 2021",
  headline: "Cookies",
  title: "ADVISEMENT",
    
    paragraph1:  "The Code Command web application is created for educational purposes only. The application features may be functional, but the content is completely fabricated and offers little to no instructional value and is intended for demonstration purposes only. The content contained in this document is for demonstration purposes, and in no way intends to represent, convey, or enforce any legal covenants.",
    paragraph2: `Code Command, Inc. and its affiliates and subsidiaries (collectively, "Code Command," "we," "our," or "us") are committed to protecting your privacy. We have prepared this Privacy Policy to describe our practices regarding the information processed by our products and any other Code Command products that link to this Privacy Policy (collectively "Products").`,
  title2: "DATA COLLECTED VIA TECHNOLOGY",
    paragraph5:
      "Our servers (which may be hosted by a third party service provider i.e. Heroku) collect information from you, including browser type, operating system, Internet Protocol (IP) address (a number that is automatically assigned to your computer when you use the Internet, which may vary from session to session), domain name, and/or a date/time stamp for your visit. We also use cookies and web beacons (as described below) and navigational data like Uniform Resource Locators (URL) to gather information regarding the date and time of your visit and the solutions and information for which you searched and which you viewed. Like most Internet services, we automatically gather this data and store it in log files each time you visit our Site, use our Apps, or access your account on our network. We may link this automatically-collected data to personally identifiable information. Our use of information received from Gmail APIs will adhere to Google's Limited Use Requirements.“Our Product’s use and transfer to any other application of information received from Google APIs will adhere to Google API Services User Data Policy, including the Limited Use requirements.”",
    paragraph6:
      "“COOKIES” are small pieces of information that a website sends to your computer’s hard drive while you are viewing a website. We may use both session cookies (which expire once you close your web browser) and persistent cookies (which stay on your computer until you delete them) to provide you with a more personal and interactive experience on our Site.",
    paragraph7: "“WEB BEACONS” are digital images we use to log information on our Site and in our emails. We use web beacons to manage cookies, count visits, and to learn what marketing works and what does not. We also use web beacons to tell if you open or act on our emails.",
    paragraph8: `“FLASH  COOKIES” are used to store your preferences such as volume control or to display content based upon what you view on our websites to personalize your visit. Third party partners who provide certain features on our websites, such as videos, may place Flash cookies on your device. Flash cookies are different from other cookies because of the amount of, type of, and way in which data is stored. Cookie management tools provided by your browser usually will not remove Flash cookies. To learn more about Flash cookies, who has placed Flash cookies on your device, and how to manage privacy and storage settings for Flash cookies click on the link 'Cookies' below this page`,
    paragraph9: "We do not permit third parties who place or track Flash cookies to use the personal information they collect for advertising or marketing purposes.",
    paragraph10: "Most web browsers are set to accept cookies by default. If you prefer, you can typically remove and reject cookies from our Site with your browser settings. If you remove or reject our cookies, it will affect how our Site and Services work for you.",
  title3: "ANALYTICS",
    paragraph11: "We use analytics services to help analyze how users use the Site and Apps. These services use cookies and scripts to collect and store information such as how users interact with our Apps, errors users encounter when using our apps, device identifiers, how often users visit the Site, what pages they visit, and what other sites they used prior to coming to the Site. We use the information we get from Google Analytics only to improve our Site, our Apps, and our Services.",
  title4: "USE OF YOUR DATA.",
    paragraph14: "In general, personal information you submit to us is used either to respond to requests that you make, or to aid us in serving you better. Code Command uses your personal information in the following ways: to create and maintain your account; to identify you as a user in our system; to operate, maintain, and improve our Site, Apps, and Services; to personalize and improve your experience; to send you administrative e-mail; to respond to your comments or inquiries; to protect, investigate, and deter against fraudulent, unauthorized, or illegal activity; and to make telephone calls to you, from time to time, as a part of secondary fraud protection or to solicit your feedback with your permission."
};

export const templateObjTerms = {
  primary: true,
  lightBg: true,
  lightLastUpdate: true,
  lightText: false,
  lightTextDesc1: false,
  lastUpdate: "Last Updated: November 20, 2021",
  headline: "Terms of Use",
  title: "ADVISEMENT",
  paragraph1: "The Code Command web application is created for educational purposes only. The application features may be functional, but the content is completely fabricated and offers little to no instructional value and is intended for demonstration purposes only. The content contained in this document is for demonstration purposes, and in no way intends to represent, convey, or enforce any legal covenants.",
  title2: "TERMS OF SERVICE",
  paragraph5: "Code Command, Inc. and its respective successors and assigns (“Code Command,” “we,” “us” or “our”) welcomes you. Code Command’s mission is to to make learning about technology accessible Code Command.com (collectively, the “Code Command Site”) in furtherance of its mission. This Terms of Use Agreement (“Terms”) governs your use of the Code Command Site, including the web content, products, technology and services provided by Code Command and available on the Code Command Site (collectively the “Code Command Services”). Please carefully read these Terms before accessing the Code Command Services as they govern use of the Code Command Services by all persons and entities, including you (each a “User”), and constitute an agreement between you and Code Command. By accessing the Code Command Services, you signify that you have read the Terms and agree to be bound by them and the Code Command Privacy Policy, which is incorporated herein and available at https://Code Command.com/Code Command-privacy-policy/, as well as any applicable laws and regulations. We may update these Terms from time to time. Each time you use any of the Code Command Services, the current version of the Terms will apply. Accordingly, when you use the Code Command Services, you should check the date of the Terms (which appears at the top of this page) and review any changes. If you do not agree with the Terms, you may not access or otherwise use the Code Command Services. Where applicable under law, these Terms constitute a writing signed by you.",
  title3: "Intellectual Property",
  paragraph11: "We respect the intellectual property rights of others, and we ask that you do the same. Except for any third-party content used as part of the Code Command Site, the data, and materials on the Code Command Site, including without limitation text, software, graphics, logos, photos, music, videos, and all other audible, visual, or downloadable materials, as well as the selection, organization, coordination, compilation and overall look and feel of the Code Command Site (collectively, “Code Command Content”) are the intellectual property of Code Command. Code Command Content is protected by copyright, trademark and other intellectual property laws and all ownership rights remain with us. We reserve all rights in and to Code Command Content. Code Command Content may not be distributed, downloaded, modified, reused, copied, reproduced, transferred, displayed, reposted, transmitted, disseminated, sold, published, broadcast, or circulated or otherwise used without the express written permission of Code Command.",
  paragraph12: "Code Command reserves the right to take any legal or technical remedies to prevent the violation of the Code Command Terms and to protect the Code Command Services, Users and the rights and property of Code Command and its affiliates. If you violate these Terms, your permission to use the Code Command Services automatically terminates and you must immediately destroy any copies you have made of the Code Command Content.",
  paragraph13: "If you wish to request permission to use any Code Command Content in a manner otherwise prohibited under these Terms, please contact:",
  address5: "privacy@code-command.com",
  address6: "Code Command, Inc % Dewey, Cheatem, & Howe, LLP; ",
  address7: "One Park Avenue, New York, NY 10001",
  address8: "212-111-1111",
  title4: "User Submissions",
  paragraph14: "You are solely responsible for your conduct, and for any messages, notes, data, text, information, graphics, photos, audio and video clips, advertisements, listings, links, and other content, in any form (“User Submissions”) that you submit, upload, publish, post or display on or through the Code Command Services. While Code Command has the right to monitor activity and content associated with the Code Command Services, and may choose to prescreen User Submissions, we are not obligated to do so. Without limiting the generality of the foregoing, you acknowledge and agree that Code Command may use certain automated filters and other tools to block spam and to categorize and arrange User Submissions on the Code Command Services. We may, but will have no obligation to, remove or limit access to User Submissions originating from any User we believe in our sole discretion to be unlawful, abusive, harassing, fraudulent, threatening, libelous, defamatory, obscene, or otherwise objectionable, or that infringes or violates any party’s intellectual property or other proprietary rights or these Terms. Further, under no circumstances does Code Command have any obligation to verify the accuracy, timeliness, or truthfulness of any User Submissions, nor to monitor any User on the Code Command Site. You are responsible at your sole cost and expense for creating backup copies and replacing any User Submissions that you post or store on the Code Command Services.",
  paragraph15: "You understand that Code Command and Code Command affiliates are not responsible for User misuse or misappropriation of any content or information that you post anywhere on the Code Command Services.",
  paragraph16: "You understand that in using the Code Command Services, you may be exposed to User Submissions that you find offensive, indecent, and objectionable. You can contact us to let us know of content that you find objectionable. We may investigate the complaints and violations of our policies that come to our attention and may take action that we believe is appropriate, including, but not limited to, issuing warnings, removing the content, or terminating User accounts. However, because situations and interpretations vary, we also reserve the right not to take any action. Under no circumstances will Code Command be liable in any way for any User Submissions, including but not limited to errors or omissions in any User Submissions, or for any loss or damage of any kind incurred as a result of the use of, access to, or denial of access to, any User Submissions posted, emailed, transmitted, or otherwise made available via the Code Command Services.",
  paragraph17: "In posting any User Submissions, you represent that you have all rights necessary to post such User Submissions without violation of any intellectual property or other rights or any laws or regulations. You further understand and agree that by posting any User Submissions on the Code Command Services, or otherwise providing any User Submissions to Code Command, such as any published project the User creates, or other materials you submit to Code Command through the Code Command Services, you are granting Code Command a world-wide, royalty-free, non-exclusive license to use, reproduce, publish, translate, modify, adapt and distribute that content (in whole or in part) for use in connection with or related to the Code Command Services, or any part thereof, for the full term of any copyright that may exist in such User Submissions, and that the foregoing license includes a right for Code Command to make such User Submissions available to third parties, in each case without any compensation or obligation to you, subject to the Code Command Privacy Policy. You hereby further grant Code Command the right to edit, copy, publish and distribute any User Submissions made available on or through the Code Command Site by you, for any lawful purpose. You also permit any other User to access, view, store or reproduce any content or links posted on the Code Command Site by you, subject to the Terms. Code Command does not assert any ownership over any User Submissions. Rather, as between you and us, subject to the rights granted to us in these Terms, you retain full ownership of all of your User Submissions and any intellectual property rights, or other proprietary rights associated with your User Submissions. Finally, you understand that Code Command may use your first name when reproducing, publishing, or distributing such User submitted content (in whole or in part) for use in connection with or related to the Code Command Services.",
  paragraph18: "You further understand and agree that Code Command may preserve User Submissions, and may also disclose User Submissions, including personally identifiable information (notwithstanding the Privacy Policy), if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to: (a) comply with legal process; (b) enforce the terms or any other applicable agreement or policy; (c) respond to claims that any User Submissions violates the rights of third parties; or (d) protect the rights, property, or personal safety of Code Command, its Users and the public. Without limiting the generality of the foregoing, Code Command may be required to disclose information pertaining to User Submissions to individuals asserting rights under the Digital Millennium Copyright Act, and you expressly authorize Code Command to comply with any and all lawful notices, subpoenas, court orders or warrants without prior notice to you.",
  paragraph19: "Finally, you understand that should you provide Code Command with a testimonial, you are granting Code Command a world-wide, royalty-free, non-exclusive license to use, reproduce, publish, translate, modify, adapt, and distribute that content (in whole or in part) for use in connection with or related to the Code Command Services."
}