import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "../../globalStyles";
import AnimatedLoad from "../../pages/Loading/AnimatedLoad";

import {
  REMOVE_FROM_CART,
  UPDATE_CART_QUANTITY,
  ADD_TO_CART,
  UPDATE_PRODUCTS,
} from "../../utils/actions";
import { QUERY_PRODUCTS } from "../../utils/queries";
import { idbPromise } from "../../utils/helpers";
import {
  InfoSec,
  InfoSecWhite,
  InfoRow2,
  InfoColumn,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrapper,
  Img,
} from "../InfoSection/InfoSection.elements";

import { ButtonDetail } from "../../containers/details/Details.Elements";
import { BioLogo, ProductQuantityLabel, ProductQuantitySlider } from "../Products/ProductsElements";
import { theme } from "../../theme";

export default function Details({
  primary,
  lightBg,
  lightTopLine,
  lightText,
  lightTextDesc,
  buttonAdd,
  buttonRemove,
  imgStart,
  start,
}) {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { id } = useParams();

  const [currentProduct, setCurrentProduct] = useState({});

  const { loading, data } = useQuery(QUERY_PRODUCTS);

  const { products, cart } = state;

  const { _id, maxQuantity } = currentProduct;
  // Initialize the default quantity for "Curry de base" with the maximum value
  const [baseQuantity, setCurryDeBaseQuantity] = useState(maxQuantity);

  useEffect(() => {
    // in global store
    if (products.length) {
      setCurrentProduct(products.find((product) => product._id === id));
      setCurryDeBaseQuantity(products.find((product) => product._id === id).maxQuantity);
    }
    // retrieved from server
    else if (data) {
      dispatch({
        type: UPDATE_PRODUCTS,
        products: data.products,
      });

      data.products.forEach((product) => {
        idbPromise("products", "put", product);
      });
    }
    // get cache from idb
    else if (!loading) {
      idbPromise("products", "get").then((indexedProducts) => {
        dispatch({
          type: UPDATE_PRODUCTS,
          products: indexedProducts,
        });
      });
    }
    
  }, [products, data, loading, dispatch, id]);

  const addToCart = () => {
    const idQuantity = _id + ":" + baseQuantity;
    const itemInCart = cart.find((cartItem) => cartItem._id === idQuantity && cartItem.curryQuantity === baseQuantity);

    if (itemInCart && itemInCart.curryQuantity === baseQuantity) {
      dispatch({
        type: UPDATE_CART_QUANTITY,
        _id: itemInCart._id,
        purchaseQuantity: parseInt(itemInCart.purchaseQuantity) + 1,
      });

      idbPromise("cart", "put", {
        ...itemInCart,
        purchaseQuantity: parseInt(itemInCart.purchaseQuantity) + 1,
      });
    } else {
      dispatch({
        type: ADD_TO_CART,
        product: { ...currentProduct, _id: idQuantity, purchaseQuantity: 1, curryQuantity: baseQuantity, maxQuantity: maxQuantity },
      });

      idbPromise("cart", "put", { ...currentProduct, purchaseQuantity: 1, curryQuantity: baseQuantity, maxQuantity: maxQuantity });
    }
  };

  const removeFromCart = () => {
    dispatch({
      type: REMOVE_FROM_CART,
      _id: currentProduct._id,
    });

    idbPromise("cart", "delete", { ...currentProduct });
  };
  return (
    <>
      {currentProduct && cart ? (
        <>
          <InfoSecWhite lightBg={lightBg}>
            <Container>
              <InfoRow2 imgStart={imgStart}>
                <InfoColumn>
                  <TextWrapper>
                    <TopLine lightTopLine={lightTopLine}>
                      {currentProduct.name}
                    </TopLine>
                    <Heading lightText={lightText}></Heading>
                    <Subtitle lightTextDesc={lightTextDesc}>
                      {currentProduct.description}
                    </Subtitle>
                    <Subtitle lightTextDesc={lightTextDesc}>
                      <strong>Prix: </strong>{currentProduct.price}€
                    </Subtitle>
                    <>
                      <ButtonDetail
                        big
                        primary={primary}
                        onClick={addToCart}
                      >
                        {buttonAdd}
                      </ButtonDetail>
                      <ButtonDetail
                        big
                        primary={primary}
                        disabled={
                          !cart.find((p) => p._id === currentProduct._id)
                        }
                        onClick={removeFromCart}
                      >
                        {buttonRemove}
                      </ButtonDetail>
                    </>
                  </TextWrapper>
                  <ProductQuantityLabel style={{ color: theme.primaryColor }}>
                    Curry doux: {baseQuantity}/{maxQuantity}
                  </ProductQuantityLabel>
                  <ProductQuantitySlider
                    type="range"
                    min={0}
                    max={maxQuantity}
                    step={1}
                    value={maxQuantity - baseQuantity} // Inverted value
                    onChange={(e) => setCurryDeBaseQuantity(maxQuantity - parseInt(e.target.value))} // Inverted value
                  />
                  <ProductQuantityLabel style={{ color: "#b00" }}>
                    Curry fort: {maxQuantity - baseQuantity}/{maxQuantity}
                  </ProductQuantityLabel>
                </InfoColumn>
                <InfoColumn>
                  <ImgWrapper start={start}>
                    <Img
                      src={`${currentProduct.image}`}
                      alt={currentProduct.name}
                    />
                    <BioLogo src={`${process.env.PUBLIC_URL}/images/AB_image_full.png`} alt="Bio" style={{ position: "relative", top: '10px', right: '100px' }} />
                  </ImgWrapper>
                </InfoColumn>
              </InfoRow2>
            </Container>
          </InfoSecWhite>
        </>
      ) : null}
      {loading ? <AnimatedLoad /> : null}
    </>
  );
}
