import React from "react";
import styled from "styled-components";
import { theme } from "../../theme";
import logo from "../../assets/tabla.svg";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px); /* Adjust the blur strength as needed */
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; /* Modified */
  align-items: center;
  height: 100%; /* Added */
`;

const LogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border: 2px solid ${theme.primaryColor};
  border-radius: 50%;
  margin-top: 20px;
`;

const Logo = styled.img`
  max-width: 100px; /* Adjust the width as needed */
  /* You can add more styles to your logo if necessary */
`;

const Subtitle = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: #000;
  margin-top: 10px;
  margin-bottom: 20px; /* Added */
  font-family: "Charlemagne Std" /* Modified */
`;

const Message = () => {
  return (
    <Container>
      <LogoContainer>
        <LogoBox>
          <Logo href="/" src={logo} alt="Logo" />
        </LogoBox>
        <Subtitle>Tâl India Curry</Subtitle>
      </LogoContainer>
    </Container>
  );
};

export default Message;
