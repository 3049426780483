import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  ProductTitle,
  ProductCard,
  ProductImg,
  ProductInfo,
  ProductPrice,
  ProductButton,
  ProductQuantitySlider,
  ProductQuantityLabel,
  BioLogo
} from "../Products/ProductsElements";
import { useSelector, useDispatch } from "react-redux";
import { ADD_TO_CART, UPDATE_CART_QUANTITY } from "../../utils/actions";
import { idbPromise } from "../../utils/helpers";
import { theme } from "../../theme";

export default function ProductItem(item) {
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const { image, name, _id, price, quantity, maxQuantity } = item;
  // const { cart } = state;

  // Initialize the default quantity for "Curry de base" with the maximum value
  const [baseQuantity, setCurryDeBaseQuantity] = useState(maxQuantity);

  const addToCart = () => {
    const idQuantity = _id + ":" + baseQuantity;
    const itemInCart = cart.find((cartItem) => cartItem._id === idQuantity && cartItem.curryQuantity === baseQuantity);
    if (itemInCart && itemInCart.curryQuantity === baseQuantity) {
      dispatch({
        type: UPDATE_CART_QUANTITY,
        _id: itemInCart._id,
        purchaseQuantity: parseInt(itemInCart.purchaseQuantity) + 1,
      });
      idbPromise("cart", "put", {
        ...itemInCart,
        purchaseQuantity: parseInt(itemInCart.purchaseQuantity) + 1,
      });
    } else {
      dispatch({
        type: ADD_TO_CART,
        product: { ...item, _id: idQuantity, purchaseQuantity: 1, curryQuantity: baseQuantity, maxQuantity: maxQuantity },
      });
      idbPromise("cart", "put", { ...item, _id: idQuantity, purchaseQuantity: 1, curryQuantity: baseQuantity, maxQuantity: maxQuantity });
    }
  }

  return (
    <ProductCard>
      <Link to={`/products/${_id}`}>
        <ProductImg src={`${image}`} alt={name} />
        <BioLogo src={`${process.env.PUBLIC_URL}/images/AB_image_full.png`} alt="Bio" />
      </Link>

      <ProductInfo>
        <Link to={`/products/${_id}`} style={{ textDecoration: "none" }}>
          <ProductTitle>{name}</ProductTitle>
        </Link>
        <ProductPrice>{price}€</ProductPrice>


        <ProductQuantityLabel style={{color: theme.primaryColor}}>
          Curry doux: {baseQuantity}/{maxQuantity}
        </ProductQuantityLabel>
        <ProductQuantitySlider
          type="range"
          min={0}
          max={maxQuantity}
          step={1}
          value={maxQuantity - baseQuantity} // Inverted value
          onChange={(e) => setCurryDeBaseQuantity(maxQuantity - parseInt(e.target.value))} // Inverted value
        />
        <ProductQuantityLabel style={{color: "#b00"}}>
          Curry fort: {maxQuantity - baseQuantity}/{maxQuantity}
        </ProductQuantityLabel>

        <ProductButton onClick={addToCart}>Ajouter au panier</ProductButton>
      </ProductInfo>
    </ProductCard>
  );
}
