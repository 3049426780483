import { Link } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../theme";

export const PricingSection = styled.div`
  padding: 100px 0 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${theme.backgroundColor};
`;

export const PricingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  @media screen and (max-width: 960px) {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const PricingHeading = styled.h1`
  color: ${theme.titleColor}; /* Curry theme title color */
  font-size: 48px;
  margin-bottom: 24px;
`;

export const PricingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const PricingCard = styled(Link)`
  background: #1d1d1d; /* Curry theme card background color */
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
  width: 280px;
  height: 525px;
  text-decoration: none;
  border-radius: 4px;
  color: #fff; /* Curry theme text color */

  &:nth-child(2) {
    margin: 24px;
  }

  &:hover {
    transform: scale(1.06);
    transition: all 0.3s ease-out;
    background: #e5cc69; /* Curry theme hover background color */
    color: #1d1d1d; /* Curry theme hover text color */
  }

  @media screen and (max-width: 960px) {
    width: 90%;

    &:hover {
      transform: none;
    }
  }
`;

export const PricingCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
  padding: 24px;
  align-items: center;
`;

export const PricingCardIcon = styled.div`
  margin: 24px 0;
`;

export const Img = styled.img`
  width: 100px;
  height: 100px;
`;

export const PricingCardPlan = styled.h3`
  margin-bottom: 5px;
  font-size: 1.1rem;
`;

export const PricingCardCost = styled.h4`
  font-size: 40px;
  margin: 1rem;
`;

export const PricingCardLength = styled.p`
  font-size: 14px;
  margin-bottom: 0rem;
`;

export const PricingCardFeatures = styled.ul`
  margin: 16px 0 32px;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a9b3c1; /* Curry theme feature text color */
`;

export const PricingCardFeature = styled.li`
  margin: 5px;
  margin-left: 10px;
  line-height: 1.5rem;
`;
