import React from "react";
import { Link } from "react-router-dom";
import {
  CartWrap,
  Img,
  CartInfo,
  CartTitle,
  Input,
  TrashIcon,
} from "./ItemsElement";

import { useDispatch } from "react-redux";
import { REMOVE_FROM_CART, UPDATE_CART_QUANTITY, updateCartQuantity } from "../../utils/actions";
import { idbPromise } from "../../utils/helpers";
import { removeHyphensAndCapitalize } from "../../utils/helpers";

const CartItems = ({ item }) => {
  const dispatch = useDispatch();

  const removeItem = (item) => {
    dispatch(updateCartQuantity(item._id, 0, item.curryQuantity));
    idbPromise("cart", "delete", { ...item });
  };

  const onChange = (e) => {
    const value = e.target.value;
    dispatch(updateCartQuantity(item._id, parseInt(value), item.curryQuantity));
    idbPromise("cart", "put", { ...item, purchaseQuantity: parseInt(value) });
  };
  return (
    <CartWrap>
      <div>
        <Link to="/checkout">
          <Img
            src={`${item.image}`}
            alt={removeHyphensAndCapitalize(item.name)}
          />
        </Link>
      </div>
      <CartInfo>
        <Link to="/checkout">
          <CartTitle>{item.name}</CartTitle>
        </Link>
        {/* only display with text if not 0 */}
        {item.curryQuantity !== 0 && (
          <h4 style={{color:"orange"}}>
            {item.curryQuantity} curry standard <br/>
          </h4>
        )}
        {item.maxQuantity - item.curryQuantity !== 0 && (
          <h4 style={{color:"red"}}>
            {item.maxQuantity - item.curryQuantity}{" "}
            variant fort <br/>
          </h4>
        )}


        <span>{item.price}€</span>
        <div>
          <span>Qté:</span>
          <Input
            type="number"
            placeholder="1"
            value={item.purchaseQuantity}
            onChange={onChange}
          />
          <span role="img" aria-label="trash" onClick={() => removeItem(item)}>
            <TrashIcon />
          </span>
        </div>
      </CartInfo>
    </CartWrap>
  );
};

export default CartItems;
