import React from "react";
import { Link } from "react-router-dom";
import { Container, Button } from "../../globalStyles";
import {
  StorySec,
  StoryRow,
  StoryColumn,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrapper,
  ProfileWraper,
  ImgBoxed,
  Img,
} from "./Home.elements";
import { Fade } from "react-awesome-reveal";

function HomeSection({
  primary,
  lightBg,
  topLine,
  lightTopLine,
  lightTopTitle,
  lightTopLine2,
  lightText,
  lightTextDesc,
  headline,
  description,
  img,
  img2,
  alt,
  imgStart,
  start,
  role,
  location,
  title,
}) {

  return (
    <>
      <StorySec
        $lightBg={lightBg ? +true : +false}
      >
        <Container>
          <StoryRow $imgStart={imgStart}>
            <StoryColumn>
              <Fade right duration={2000} distance="80px">
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <TextWrapper>
                    <TopLine $lightTopLine={lightTopLine}>{topLine}</TopLine>
                    <TopLine lightTopTitle={lightTopTitle}>{title}</TopLine>
                    <ProfileWraper $lightTopLine2={lightTopLine2}>
                      {role}
                    </ProfileWraper>
                    <ProfileWraper $lightTopLine2={lightTopLine2}>
                      {location}
                    </ProfileWraper>
                    <Heading $lightText={lightText}>{headline}</Heading>
                    <Subtitle $lightTextDesc={lightTextDesc}>
                      {description}
                    </Subtitle>
                    <ImgWrapper start={start} style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Img src="/images/signature.png" alt="signature" style={{ width: '100px', height: '100px' }} />
                    </ImgWrapper>
                  </TextWrapper>

                </div>
              </Fade>
            </StoryColumn>
            
            <StoryColumn>
              <Fade right duration={2000} distance="80px">
                {img && (
                  <ImgWrapper start={start}>
                    <ImgBoxed src={img} alt={alt} />
                  </ImgWrapper>
                )}
                <br />
                {img2 && (
                  <ImgWrapper start={start} >
                    <ImgBoxed src={img2} alt={alt} style={{boxShadow:"none"}}/>
                  </ImgWrapper>
                )}
              </Fade>
            </StoryColumn>
          </StoryRow>
        </Container>
      </StorySec>
    </>
  );
}

export default HomeSection;
