import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_COMMENTS } from '../../utils/queries';
import { ADD_COMMENT, DELETE_COMMENT } from '../../utils/mutations'; // Import your GraphQL queries and mutations
import Auth from '../../utils/auth';
import {
  CommentSectionContainer,
  CommentInputContainer,
  CommentInput,
  CommentSubmitButton,
  CommentsList,
  CommentText,
  CommentTimestamp,
  CommentContainer,
  DeleteButton,
  LoadMoreButton
} from './CommentSection.elements'; // Import your Styled-Components


const CommentSection = () => {
  const { loading, data, } = useQuery(GET_COMMENTS);
  const [deleteCommentMutation] = useMutation(DELETE_COMMENT);
  const [addCommentMutation] = useMutation(ADD_COMMENT);
  const [showAll, setShowAll] = useState(false); // Initialize showAll state
  const [comments, setComments] = useState([]);
  const [fullTextComments, setFullTextComments] = useState([]); // Keep track of comments with full text

  useEffect(() => {
    if (data && data.comments) {
      setComments(data.comments);
    }
  }, [data]);
  const [newComment, setNewComment] = useState('');

  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleCommentSubmit = async () => {
    if (newComment) {
      // Use the addComment mutation to add a new comment

      // Check auth
      if (!Auth.loggedIn()) {
        console.log('You must be logged in to comment');
        return;
      }
      try {
        const user = Auth.getProfile().data.firstName;
        const { data } = await addCommentMutation({
          variables: {
            user,
            text: newComment,
          },
          refetchQueries: [{ query: GET_COMMENTS }],
        });
        console.log(data);
        if (data && data.addComment) {
          // Comment added successfully, you can update the UI or handle errors here
          console.log('Comment added successfully');
        }
      } catch (error) {
        console.error(error);
        // Handle the error
      }

      // Clear the input field
      setNewComment('');
    }


  };

  const handleDeleteComment = async (commentId) => {
    try {
      console.log('Deleting comment...' + commentId);
      const { data } = await deleteCommentMutation({
        variables: { id: commentId },
        refetchQueries: [{ query: GET_COMMENTS }],
      });

      if (data && data.deleteComment) {
        console.log('Comment deleted successfully');
      } else {
        console.error('Failed to delete comment');
      }
    } catch (error) {
      console.error('Failed to delete comment:', error);
    }
  };

  const handleToggleFullText = (commentId) => {
    const updatedFullTextComments = [...fullTextComments];
    if (updatedFullTextComments.includes(commentId)) {
      updatedFullTextComments.splice(updatedFullTextComments.indexOf(commentId), 1);
    } else {
      updatedFullTextComments.push(commentId);
    }
    setFullTextComments(updatedFullTextComments);
  };


  return (
    <CommentSectionContainer>
      <CommentInputContainer>
        <CommentInput
          type="text"
          placeholder={Auth.loggedIn() ? "Ajouter un commentaire..." : "Veuillez vous connecter pour ajouter un commentaire"}
          value={newComment}
          onChange={handleCommentChange}
          disabled={!Auth.loggedIn()} // Désactive l'input si l'utilisateur n'est pas authentifié
        />
        <CommentSubmitButton onClick={handleCommentSubmit}>Add</CommentSubmitButton>
      </CommentInputContainer>

      <CommentsList>
        {loading ? (
          <p>Chargement des commentaires...</p>
        ) : (
          comments && (
            <>
              {comments
                .slice(0, showAll ? comments.length : 10) // Display the first 10 comments or all if 'showAll' is true
                .map((comment) => (
                  <CommentContainer key={comment._id}>
                    <CommentText
                      className={fullTextComments.includes(comment._id) ? 'show-full-text' : ''}
                      onClick={() => handleToggleFullText(comment._id)}
                    >
                      {comment.text}
                    </CommentText>
                    <CommentTimestamp>Posté par {comment.user} le: {comment.createdAt}</CommentTimestamp>
                    {(Auth.loggedIn() && Auth.getProfile().data.firstName === comment.user) && (
                    <DeleteButton onClick={() => handleDeleteComment(comment._id)}>Delete</DeleteButton>
                  )}

                  </CommentContainer>
                ))}
              {comments.length > 10 && !showAll && (
                <LoadMoreButton onClick={() => setShowAll(true)}>Charger plus de commentaires...</LoadMoreButton>
              )}
            </>
          )
        )}
      </CommentsList>
    </CommentSectionContainer>
  );
};

export default CommentSection;
