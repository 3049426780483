// routesData.js
import React from 'react';
import NavContainer from './containers/navbar/NavContainer';
import Cartbar from './containers/cart/Cartbar';
import { Footer } from './components';
import Cookies from './components/Cookies/Cookies';
import Checkout from './pages/Checkout/Checkout'; // Import the Checkout component

export const routes = [
  { path: '/' },
  { path: '/login' },
  { path: '/signup' },
  { path: '/store' },
  { path: '/histoire' },
  { path: '/checkout', component: Checkout }, // Use the Checkout component for the /checkout route
  { path: '/contact' },
  { path: '/order-history' },
  { path: '/success' },
  { path: '/privacy' },
  { path: '/cookies' },
  { path: '/terms' },
  { path: '/testimonials' },
  { path: '/code-editor' },
];

export const navbarObj = { element: <NavContainer /> };
export const cartObj = { element: <Cartbar /> };
export const footerObj = { element: <Footer /> };
export const cookieObj = { element: <Cookies /> };
