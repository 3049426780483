import React, { useEffect } from "react";
import { BtnLink } from "../../containers/cart/CartItem.Elements";
import { loadStripe } from "@stripe/stripe-js";
import { useLazyQuery } from "@apollo/react-hooks";
import { QUERY_CHECKOUT } from "../../utils/queries";
// import Announcement from "../../components/Announcement/Announcement";
import {
  Container,
  Wrapper,
  Title,
  Top,
  TopButton,
  Bottom,
  Info,
  MsgContainer,
  ShopLink,
} from "./CheckoutElements";
import CartItem from "../CartItem";
import { useSelector, useDispatch } from "react-redux";
import Auth from "../../utils/auth";
import { idbPromise } from "../../utils/helpers";
import { ADD_MULTIPLE_TO_CART } from "../../utils/actions";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY); 

const Cart = () => {
  const stateCart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [getCheckout, { data }] = useLazyQuery(QUERY_CHECKOUT);

  useEffect(() => {
    if (data) {
      stripePromise.then((res) => {
        res.redirectToCheckout({ sessionId: data.checkout.session });
      });
    }
  }, [data]);

  useEffect(() => {
    async function getCart() {
      const cart = await idbPromise("cart", "get");
      dispatch({ type: ADD_MULTIPLE_TO_CART, products: [...cart] });
    }

    if (!stateCart.length) {
      getCart();
    }
  }, [stateCart.length, dispatch]);

  function calculateTotal() {
    let sum = 0;
    stateCart.forEach((item) => {
      sum += item.price * item.purchaseQuantity;
    });
    return sum.toFixed(2);
  }

  function submitCheckout() {
    const productIds = [];
    
    stateCart.forEach((item) => {
      for (let i = 0; i < item.purchaseQuantity; i++) {
        productIds.push(item._id);
      }
    });

    getCheckout({
      variables: { 
        products: productIds
      },
    });
  }

  return (
    <Container>
      {/* <Announcement /> */}
      <Wrapper>
        <Title>
          <strong>VOTRE PANIER</strong>
        </Title>
        <Top>
          <Info>
            {stateCart.length ? (
              <>
                {stateCart.map((item, index) => (
                    <CartItem key={item._id} item={item} inverted={index % 2 === 0} />
                ))}
                <div>
                  <strong>Total: {calculateTotal()}€</strong>
                </div>
              </>
            ) : (
              <MsgContainer>
                <h3>Vous n'avez rien ajouté à votre panier.</h3>
              </MsgContainer>
            )}
          </Info>
        </Top>
        <Bottom>
          <ShopLink to="/store">
            <div style={{ with: "5rem", height: "auto" }}>
            <TopButton>Continuer les achats</TopButton>
            </div>
          </ShopLink>

          {Auth.loggedIn() ? (
            <div style={{ with: "5rem", height: "auto" }}>
              <TopButton onClick={submitCheckout} type="filled">
                Passer au paiement !
              </TopButton>
            </div>
          ) : (
            <div style={{ with: "5rem", height: "auto" }}>
              <BtnLink to="/login">
                <span>(connectez-vous pour payer)</span>
              </BtnLink>
            </div>
          )}
        </Bottom>
      </Wrapper>
    </Container>
  );
};

export default Cart;
