import React from "react";
import TestimonialsSection from "../../components/TestimonialsSection/TestimonialsSection";

export default function Testimonials() {
  return (
    <>
      <TestimonialsSection />
    </>
  );
}
