import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import logo from "../../assets/tabla.svg";
import {
  Container,
  LogoWrapper,
  Form,
  Terms,
  InputContainer,
  StyledInput,
  Status,
} from "./Input";
import { ADD_USER } from "../../utils/mutations";
import Auth from "../../utils/auth";
import { signupObj } from "./Data";

export default function Signupbar(props) {
  const [formState, setFormState] = useState({ email: "", password: "" });
  const [addUser] = useMutation(ADD_USER);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const mutationResponse = await addUser({
        variables: {
          email: formState.email,
          password: formState.password,
          firstName: formState.firstName,
          lastName: formState.lastName,
        },
      });

      if (mutationResponse.data && mutationResponse.data.addUser) {
        const token = mutationResponse.data.addUser.token;
        Auth.login(token);
        // Handle a successful user creation, e.g., redirect to a different page.
      } else {
        // Handle any other specific errors returned by your GraphQL mutation.
        console.error("Error while adding user:", mutationResponse);
        alert("Erreur lors de la création du compte");
      }
    } catch (error) {
      // Handle errors that occur during the mutation
      console.error('Error while adding user:', error);
      // You can display an error message to the user or handle the error as needed.
      // Handle duplucate key errors for email/username.
      if (error.message.includes("E11000 duplicate key error collection")) {
        alert("Ce compte existe déjà");
      } else {
        alert("Erreur lors de la création du compte");
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  return (
    <Container>
      <LogoWrapper>
        {/* <Link to="/">
          <img src={logo} alt="logo brand" />
        </Link>
        <h3 fontFamily="Charlemagne Std">
          Tâl India <span>Curry</span>
        </h3> */}
      </LogoWrapper>
      <Form onSubmit={handleFormSubmit}>
        <h3>Créer un compte</h3>
        <div>
          {signupObj.map((x, index) => (
            <InputContainer key={index}>
              <StyledInput
                placeholder={x.placeholder}
                name={x.name}
                type={x.type}
                id={x.id}
                onBlur={handleChange}
                required
                autoComplete="on"
              />
              <Status />
            </InputContainer>
          ))}
        <button type="submit">Créer un compte</button>
        </div>
      </Form>
      <div>
        <Terms>
        En m'inscrivant, j'accepte la Politique de confidentialité <br/> et les Conditions de service.
        </Terms>
        <h4>
          Vous avez déjà un compte ?
          <Link to="/login">
            <span>Se connecter</span>
          </Link>
        </h4>
      </div>
    </Container>
  );
}
