
import React, { useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { ADD_ORDER } from "../../utils/mutations";
import { idbPromise } from "../../utils/helpers";
import {theme} from "../../theme";


import styled from "styled-components";

const SuccessMessage = styled.div`
  height: 560px;
  clear: both;
  padding-top: 120px;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: ${theme.primaryColor}; /* Couleur de titre */
`;

const SubTitle = styled.h2`
  font-size: 1.5rem;
  margin-top: 20px;
  color: ${theme.primaryColor}; /* Couleur de sous-titre */
`;

const RedirectMessage = styled.h2`
  font-size: 1.2rem;
  margin-top: 10px;
  color: ${theme.primaryColor}; /* Couleur de sous-titre */
`;

const SuccessComponent = () => {
  return (
    <SuccessMessage>
      <Title>Transaction réussie !</Title>
      <SubTitle>Merci pour votre achat !</SubTitle>
      <RedirectMessage>Vous allez être redirigé vers la page d'accueil</RedirectMessage>
    </SuccessMessage>
  );
};


function Success() {
  const [addOrder] = useMutation(ADD_ORDER);

  useEffect(() => {
    async function saveOrder() {
      const cart = await idbPromise("cart", "get");
      const products = cart.map((item) => item._id);
      



      if (products.length) {
        const { data } = await addOrder({ variables: { products } });
        const productData = data.addOrder.products;

        productData.forEach((item) => {
          idbPromise("cart", "delete", item);
        });
      }

      setTimeout(() => {
        window.location.assign("/");
      }, 100000);
    }

    async function sendMailToAdmin() {
      // get data from checkout session : success_url: `${url}/success?session_id={CHECKOUT_SESSION_ID}`,
      const urlParams = new URLSearchParams(window.location.search);
      const sessionId = urlParams.get("session_id");
      //get session from stripe
      const session = await fetch(`/retrieve-session/${sessionId}`).then((res) =>
        res.json()
      );
      
      const line_items = session.line_items;
      console.log("session", session);
      const customer = session.customer_details;
      const details = session.metadata.products;
      // replace \n by <br> in details
      details.replace(/\n/g, "<br>");

      let data = {};
      if(process.env.NODE_ENV === "production")
      {

        data = {
          name: customer.name,
          email: customer.email,
          message: "Nouvelle commande !",
          line_items: line_items,
          details: details,
        };
      }
      else
      {
        data = {
          name: "test",
          email: "dev.atopy@gmail.com",
          message: "Nouvelle commande !",
          line_items: line_items,
          details: details,
        };
      }

      // send email using POST request
      console.log(data);
      fetch("/send-checkout-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if(process.env.NODE_ENV === "production") console.log("Email sent");
          if (response.ok) {
            // Handle success (e.g., show a success message)
            alert("Email sent successfully!");
          } else {
            // Handle error (e.g., show an error message)
            alert("Failed to send email.");
          }
        })
        .catch((error) => {
          // Handle network or other errors
          console.error("Error:");
          alert("An error occurred.");
        });

    }

    saveOrder();
    sendMailToAdmin();
  }, [addOrder]);

  return (
    <SuccessComponent />
  );
}

export default Success;
