import React from "react";
import HistorySection from "../../components/HistorySection/HistorySection"

export default function OrderHistory() {
  return (
    <>
      <HistorySection />
    </>
  );
}
