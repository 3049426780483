export const histoire = {
    primary: true,
    lightBg: false,
    lightTopLine: false,
    lightTopLine2: false,
    lightText: true,
    lightTextDesc: true,
    topLine: "",
    headline: "",
    description:
    "Depuis mon enfance, j’ai toujours préféré les plats rehaussés d’épices.\n "+
    "J’ai décidé très tôt, de composer mon propre curry. \n Ayant élaboré au fil du temps sa recette, je vous le propose aujourd’hui. \n\n" +
    "Les tablas sont les percussions emblématiques de l’Inde.\n" +
    "Dans la musique indienne, les tâl, sont les temps d’une mesure. \n" +
    "J’ai donc choisi mes propres tablas comme symbole de la marque.",
    role: "",
    // location: "Atlanta, GA",
    // buttonLabel: "Start your journey",
    imgStart: "",
    img: "/images/tabla.jpg",
    img2: "/images/epices.jpg",
    start: "",
    // route: "/signup",
  };

  