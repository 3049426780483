export const signupObj = [
  {
    placeholder: "Prénom",
    name: "firstName",
    type: "firstName",
    id: "firstName",
  },
  {
    placeholder: "Nom",
    name: "lastName",
    type: "lastName",
    id: "lastName",
  },
  { placeholder: "Email", name: "email", type: "email", id: "email" },
  { placeholder: "Mot de passe", name: "password", type: "password", id: "pwd" },
];
