import React from "react";
import { TrashIcon, Input } from "../../components/CartItems/ItemsElement";
import { Container } from "../../globalStyles";
import {theme} from "../../theme";

import {
  InfoSecCart,
  InfoRow2,
  InfoColumn,
  TextWrapper,
  Subtitle,
  ImgWrapperCart,
  ImgCart,
} from "../InfoSection/InfoSection.elements";
import { useDispatch } from "react-redux";
import { REMOVE_FROM_CART, UPDATE_CART_QUANTITY, updateCartQuantity } from "../../utils/actions";
import { idbPromise } from "../../utils/helpers";
const CartItem = ({ item, primary, lightTextDesc, imgStart, start, inverted }) => {
  const dispatch = useDispatch();

  const removeItem = (item) => {
    dispatch(updateCartQuantity(item._id, 0, item.curryQuantity));

    idbPromise("cart", "delete", { ...item });
  };

  const onChange = (e) => {
    const value = e.target.value;
    dispatch(updateCartQuantity(item._id, parseInt(value), item.curryQuantity));
    idbPromise("cart", "put", { ...item, purchaseQuantity: parseInt(value) });
    
  };

  return (
    <InfoSecCart style={{padding: "10px", backgroundColor: inverted ? theme.primaryColor : "#EEE", color: inverted ?  "#EEE": theme.primaryColor, borderRadius: "10px" }}>
      <Container>
        <InfoRow2 imgStart={imgStart}>
          <InfoColumn>
            <TextWrapper>
              <Subtitle
                style={{ fontWeight: "bold", fontFamily: "Charlemagne Std", fontSize: "20px" }}
                lightTextDesc={lightTextDesc}
              >
                {item.name}
              </Subtitle>
              <Subtitle lightTextDesc={lightTextDesc} style={{ fontSize: "18px" }}>
                <strong>Prix: </strong>{item.price}€
              </Subtitle>
              <Subtitle lightTextDesc={lightTextDesc} style={{fontSize: "12px", fontWeight: "700"}}>
                dont curry standard: {item.curryQuantity}
              </Subtitle>
              <>
                <span style={{ color: "#000", marginRight: "10px", fontFamily: "Charlemagne Std" }}>Qté: </span>
                <Input
                  type="number"
                  placeholder="1"
                  value={item.purchaseQuantity}
                  onChange={onChange}
                  style={{ backgroundColor: "#f5f5f5", padding: "5px", borderRadius: "5px", border: "none", marginRight: "10px" }}
                />
                <span
                  role="img"
                  aria-label="trash"
                  onClick={() => removeItem(item)}
                  style={{ cursor: "pointer", transition: "all 0.3s ease-in-out" }}
                >
                  <TrashIcon style={{ fontSize: "20px" }} />
                </span>
              </>
            </TextWrapper>
          </InfoColumn>
          <ImgWrapperCart start={start}>
            <ImgCart src={`${item.image}`} alt="item details" />
          </ImgWrapperCart>
        </InfoRow2>
      </Container>
    </InfoSecCart>
  );
};
export default CartItem;
