import React from "react";
import {
  curryObj
} from "../../containers/home/Data";
import { HomeSection } from "../../components";
import Auth from "../../utils/auth";
import Store from "../Store/Store";

export default function Home(lightBg) {
  if (Auth.loggedIn()) {
    return (
      <>
        <Store />
      </>
    );
  } else {
    return (
      <>
        <HomeSection {...curryObj} />
      </>
    );
  }
  
}
