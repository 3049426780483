import React from "react";
import { withRouter } from "../../utils/helpers";

export const BarTemplate = withRouter(({ location, element }) => {
  return (
    <>
      {location !== "/login" &&
        location !== "/signup" &&
        location !== "/privacy" &&
        location !== "/cookies" &&
        location !== "/code-editor" &&
        location !== "/terms" && <>{element}</>}
    </>
  );
});
