import styled from "styled-components";
import { theme } from "../../theme";


export const ProductsContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  padding: 5rem calc((100vw - 1300px) / 2);
  background: ${theme.backgroundColor};
  color: ${theme.textColor};
`;

export const ProductsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 4rem;
`;

export const ProductWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
`;

export const ProductCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 2rem;
  line-height: 1;
  width: 300px;
  background-color: ${theme.cardBackground};
  margin-bottom: 2rem;
  color: ${theme.textColor};
  box-shadow: 10px 13px 17px -10px ${theme.cardShadow};
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.03);
    background-color: ${theme.cardHoverBackground};
    color: ${theme.textColor};
  }
`;

export const ProductImg = styled.img`
  height: 300px;
  min-width: 300px;
  max-width: 100%;
  object-fit: cover;
  padding: 5px;
`;

export const BioLogo = styled.img`
  height: 100px;
  width: 100px;
  position: absolute;
  top: 10px;   /* Adjust the top position as needed */
  right: 10px; /* Adjust the right position as needed */
  padding: 5px;
  z-index: 1000;
`;

export const ProductsHeading = styled.h1`
  font-size: clamp(2rem, 2.5vw, 3rem);
  text-align: center;
  color: ${theme.titleColor};
  @media only screen and (max-width: 380px) {
    font-size: 1.5rem;
  }
`;

export const ProductsDesc = styled.h3`
  font-size: clamp(1rem, 1.5vw, 1rem);
  font-weight: normal;
  text-align: center;
  color: ${theme.descriptionColor};
  margin: 1rem;
  line-height: 24px;
`;

export const ProductTitle = styled.h2`
  font-weight: 400;
  font-size: 1.5rem;
  color: ${theme.titleColor};
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  text-align: center;
  flex-grow: 1;
  justify-content: space-between;
  height: 282px;
`;

export const ProductDesc = styled.p`
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: ${theme.descriptionColor};
`;

export const ProductPrice = styled.p`
  margin-bottom: 1rem;
  font-size: 2rem;
  color: ${theme.priceColor};
`;

export const ProductButton = styled.button`
  font-size: 1rem;
  padding: 1rem 4rem;
  border: none;
  border-radius: 8px;
  background: ${theme.buttonBackground};
  color: ${theme.textColor};
  transition: 0.2s ease-out;

  &:hover {
    background: ${theme.buttonHoverBackground};
    cursor: pointer;
  }
`;

export const MsgContainer = styled.div`
  height: 70vh;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
`;

export const ProductQuantitySlider = styled.input`
  width: 100%;
  height: 20px;
  -webkit-appearance: none;
  appearance: none;
  background: ${theme.sliderBackground};
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: ${theme.sliderThumbColor};
    border: 2px solid ${theme.primaryColor};
    border-radius: 50%;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: ${theme.sliderThumbColor};
    border: 2px solid ${theme.primaryColor};
    border-radius: 50%;
    cursor: pointer;
  }
`;

export const ProductQuantityLabel = styled.p`
  margin: 0.5rem 0;
  font-size: 1rem;
  font-weight: bold;
  color: ${theme.primaryColor};
  text-align: center;
  user-select: none;
  cursor: pointer;
`;
