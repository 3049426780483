import React from "react";
import { HistoireSection } from "../../components";
import { histoire } from "../../containers/histoire/Data";

export default function Histoire() {
    return (
        <>
            <HistoireSection {...histoire} />
        </>
    );
}
