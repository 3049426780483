import React from "react";
import CookieConsent from "react-cookie-consent";
import { CookieLink } from "../../globalStyles";

export default function Cookies() {
  return (
    <>
      <CookieConsent
        // debug={true}
        style={{ background: "rgba(16,21,34,0.78)", textAlign: "left" }}
        cookieName="talindia-cookie-consent"
        buttonStyle={{
          color: "#fff",
          background: "#4B59F7",
          fontSize: "18px",
          borderRadius: "4px",
        }}
        buttonText={"Compris !"}
        buttonClasses="btn btn-primary"
        expires={150}
      >
        Ce site utilise des cookies. Voir notre{" "}
        <CookieLink to="/cookies">politique de confidentialité</CookieLink> pour plus d'infos.
      </CookieConsent>
    </>
  );
}
