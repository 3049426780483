import React from "react";
import styled from "styled-components";
import { theme } from "../../theme";
import logo from "../../assets/tabla.svg";

const Message = () => {
  return (
    <Container>
      <Logo href="/" src={logo} alt="Logo" />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Add this for positioning the logo */
  z-index: -1; /* Add this to ensure the content is above the pseudo-element */

  h1 {
    font-family: "Charlemagne Std";
    font-size: 65px;
    font-weight: 900;
    color: ${theme.invertedTitleColor};

    @media (max-width: 900px) {
      display: none;
      /* prevent from being on top of other components */
    }
  }
`;

const Logo = styled.img`
  position: absolute;
  bottom: 10px;
  right: 10px;
  max-width: 100px; /* Adjust the width as needed */
`;

export default Message;
