import React from "react";
import StoreSection from "../../components/StoreSection/StoreSection";
import CommentSection from "../../components/CommentSection/CommentSection";

export default function Courses() {
  var coursesHeader = [
    {
      heading: "Time to practice and learn",
      desc: "This page contains all available courses on Code Command, however, if you're a beginner, the recommended way to learn here is through a learning path. ",
    },
  ];

  return (
    <>
      {coursesHeader.map((a, index) => (
        <StoreSection key={index} heading={a.heading} description={a.desc} />
      ))}
      
      <CommentSection />
    </>
  );
}
