import React from "react";
import { Instagram } from "./Footer.elements";

export const aboutDB = [
  {
    title: "A propos de nous",
    details: [
      { route: "/histoire", des: "Notre histoire" },
      // { route: "/testimonials", des: "Ils nous font confiance" },
      { route: "/privacy", des: "Politique de confidentialité" },
      { route: "/terms", des: "Conditions d'utilisation" },
      { route: "/cookies", des: "Politique de cookies" },
    ],
  },
  {
    title: "Nous Contacter",
    details: [
      { route: "/contact", des: "Nous contacter" },
      { route:"tel:+33677716669" , des: "+33 6 77 71 66 69" },
      { route:"mailto:talindiacurry@proton.me", des: "talindiacurry@proton.me" },
    ],
  },
];

export const socialDB = [
  {
    href: "https://www.instagram.com/talindiacurry/",
    name: <Instagram>Instagram</Instagram>,
  },
];

export const iconsDB = [
  //instagram
  {
    href: "https://www.instagram.com/talindiacurry/",
    name: <Instagram />,
  },

  
];
