import styled from "styled-components";
import { theme } from "../../theme";

export const ButtonDetail = styled.button`
  border-radius: 4px;
  background: ${({ primary }) => (primary ? theme.primaryColor : theme.secondaryColor)};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
  margin: 5px;
  color: ${theme.textColor};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? "0.8" : "1")};
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);

  &:hover {
    color: #fff;
    transform: translateY(-7px);
    background: ${theme.hoverBackgroundColor};
    transition: all 0.3s ease 0s;
    background-color: ${({ primary }) =>
      primary ? theme.hoverPrimaryColor : theme.hoverSecondaryColor};
  }
`;
