// theme.js

export const theme = {
  // Colors
  primaryColor: '#6BB5A0', // Green
  secondaryColor: '#66B2FF', // Blue
  textColor: '#333', // Black
  backgroundColor: '#F0F8FF', // Light Blue (Alice Blue)

  // Buttons
  buttonBackground: '#6BB5A0', // Green
  buttonHoverBackground: '#66B2FF', // Blue
  buttonTextColor: '#fff', // White
  buttonTextHoverColor: '#333', // Black
  secondaryButtonBackground: '#66B2FF', // Blue
  secondaryButtonHoverBackground: '#6BB5A0', // Green
  secondaryButtonTextColor: '#fff', // White
  secondaryButtonTextHoverColor: '#333', // Black

  // Links
  linkColor: '#6BB5A0', // Green
  linkHoverColor: '#66B2FF', // Blue

  // Hover States
  hoverBackgroundColor: '#66B2FF', // Blue
  hoverTextColor: '#333', // Black

  // Icon Colors
  iconColor: '#333',
  iconHoverColor: '#6BB5A0',

  // Input Colors
  inputColor: '#333',
  inputHoverColor: '#6BB5A0',

  // Accent Color
  accentColor: '#6BB5A0',

  // Title Color
  titleColor: '#333',
  invertedTitleColor: '#fff',

  // Navbar Colors
  navbarBackground: '#6BB5A0', // Green
  navbarHoverColor: '#DDD', // Blue

  // Product Card Colors
  cardBackground: '#F0F8FF', // Light Blue (Alice Blue)
  cardShadow: '#6BB5A0', // Green
  cardHoverBackground: '#bddbd3', 

  // Description Color
  descriptionColor: '#333',

  // Price Color
  priceColor: '#333',

  // Slider Color 
  sliderBackground: '#E5E5E5', // Gray for the slider background
  sliderThumbColor: '#6BB5A0', // Green for the slider thumb


  hotBackground: '#FF0000', // Red
};