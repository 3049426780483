import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Form,
  StyledTextarea,
  StatusMsg,
} from "./ContactSection.Elements";
import {
  InputContainer,
  StyledInput,
  Status,
  ContactBtn,
} from "../../containers/login/Input";
import { validateEmail } from "../../utils/helpers";

export default function ContactSection() {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const { name, email, subject, message } = formState;

  const handleChange = (e) => {
    if (e.target.name === "email") {
      const isValid = validateEmail(e.target.value);
      if (!isValid) {
        setErrorMessage("Votre email est invalid.");
      } else {
        setErrorMessage("");
      }
    } else {
      if (!e.target.value.length) {
        setErrorMessage(`${e.target.placeholder} est requis.`);
      } else {
        setErrorMessage("");
      }
    }
    if (!errorMessage) {
      setFormState({ ...formState, [e.target.name]: e.target.value });
    }
  };

  const form = useRef();

  function send(e) {
    e.preventDefault(); // Prevent the default form submission
  
    if (errorMessage) {
      // If there's an error message, do not proceed with the form submission
      return;
    }
  
    // Prepare the data to send to the server
    const data = {
      name: formState.name,
      email: formState.email,
      subject: formState.subject,
      message: formState.message,
    };
  
    // Send a POST request to the server-side endpoint
    fetch("/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          // Handle success (e.g., show a success message)
          alert("Email sent successfully!");
          // Optionally, clear the form fields after a successful submission
          setFormState({
            name: "",
            email: "",
            subject: "",
            message: "",
          });
        } else {
          // Handle error (e.g., show an error message)
          alert("Failed to send email.");
        }
      })
      .catch((error) => {
        // Handle network or other errors
        console.error("Error:", error);
        alert("An error occurred.");
      });
  }
  

  

  return (
    <>
      <Container id="contact">
        <Form ref={form} onSubmit={send}>
          <h3>Nous contacter :</h3>
          <InputContainer>
            <StyledInput
              placeholder="Nom Prénom"
              name="name"
              type="text"
              id="name"
              defaultValue={name}
              onBlur={handleChange}
              required
              autoComplete="off"
            />
            <Status />
          </InputContainer>
          <InputContainer>
            <StyledInput
              placeholder="Sujet"
              name="subject"
              type="subject"
              id="subject"
              defaultValue={subject}
              onBlur={handleChange}
              required
              autoComplete="off"
            />
            <Status />
          </InputContainer>
          <InputContainer>
            <StyledInput
              placeholder="Adresse Email"
              name="email"
              type="email"
              id="email"
              defaultValue={email}
              onBlur={handleChange}
              required
              autoComplete="off"
            />
            <Status />
          </InputContainer>
          <InputContainer>
            <StyledTextarea
              placeholder="Message"
              name="message"
              type="message"
              id="message"
              defaultValue={message}
              onBlur={handleChange}
              required
              autoComplete="off"
              rows="5"
            />
            <StatusMsg />
          </InputContainer>
          {errorMessage && (
            <div id="submitErrorMessage">
              <div>{errorMessage}</div>
            </div>
          )}
          <InputContainer>
            <ContactBtn type="submit">Envoyer le message</ContactBtn>
          </InputContainer>
          <h4 style={{marginLeft: "-1.5rem"}}>
            Nouveau sur la boutique?
            <Link to="/signup">
              <span>Créer un compte</span>
            </Link>
          </h4>
        </Form>
      </Container>
    </>
  );
}
